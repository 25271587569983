.mobile-toggle-button {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 999;

  background-image: linear-gradient(280deg, #00aaf1, #03488a) !important;
  color: #fff !important;
}

.hidden {
  display: none;
}

.mobile-collapse-button {
  font-size: 18px;
  padding: 5px;
}

@media (max-width: 768px) {
  .custom-sider {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px !important;
    transform: translateX(-100%);
    transition: all 0.3s ease-in-out;
  }

  .custom-sider.expanded {
    transform: translateX(0);
  }
}
