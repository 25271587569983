/* CRM Responsive */
/* ============== */

/* CRM Login Start*/

@media (max-width: 800px) {
  .loginpage .ant-col-lg-order-2 main {
    width: 80% !important;
    max-width: 80% !important;
  }
  .loginpage .list-checked ul {
    width: 100% !important;
  }
  .loginpage .list-checked ul li {
    width: 100% !important;
  }
  .loginpage ul.list-checked * {
    width: 100% !important;
  }
  .loginpage .ant-col-lg-order-1 > main > div > div:nth-child(7) img {
    width: 40px !important;
    max-width: 40px !important;
    height: auto !important;
    object-fit: contain;
  }
  .loginpage .ant-col-lg-order-2 main img {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }
  .loginpage .ant-col-lg-order-1 > main > div > img {
    margin: 0 !important;
    width: 100% !important;
  }
  .loginpage .ant-col-lg-order-1 > main > div > h3 {
    font-size: 30px !important;
  }
  .loginpage .ant-col-lg-order-2 main h1 {
    font-size: 30px !important;
    text-align: center;
  }
  .loginpage .ant-col-lg-order-2 main img {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    filter: brightness(0) invert(1);
    margin-bottom: 30px !important;
  }
  .loginpage > div > div > div {
    height: auto !important;
    min-height: 0 !important;
    padding: 50px 0 !important;
  }

  /* CRM Login End*/

  /* CRM Overview Start*/

  #root > .ant-layout > div > div:nth-child(2) > div {
    margin-top: 0 !important;
  }
  .logo-attendance {
    top: -30% !important;
  }
  .greeting-card .ant-card-body > div:nth-child(3) {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
  }
  .greeting-card .ant-card-body > div:nth-child(3) h2 {
    margin-bottom: 0 !important;
  }
  .greeting-card .ant-card-body > div:nth-child(3) h2 svg {
    margin-left: 30px !important;
  }
  .ant-col-12 {
    width: 100% !important;
    flex: 100% !important;
    max-width: 100% !important;
  }
  .ant-card-head-title h3 {
    font-size: 20px !important;
  }
  .attendance-card-right > div > div {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  td,
  th {
    padding: 5px !important;
  }
  td .ant-avatar {
    width: 20px !important;
    height: 20px !important;
  }
  .ant-table {
    font-size: 10px !important;
  }
  .ant-btn.ant-btn-icon-only .anticon {
    font-size: 10px !important;
  }
  .ant-col-6 {
    width: 50% !important;
    flex: 50% !important;
    max-width: 50% !important;
  }
  html body .ant-col-6 .attend-card > div > div {
    width: 100% !important;
    text-align: center !important;
    padding: 0 !important;
  }
  .attend-card .ant-card-body {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
  }
  .ant-card.ant-card-bordered.whiteBox.shadow {
    padding: 0 !important;
  }
  .ant-table-content .ant-btn-link {
    padding: 0 !important;
    font-size: 10px !important;
    width: 50px;
    white-space: break-spaces;
  }
  .ant-table-content .ant-btn-link {
    padding: 0 !important;
    font-size: 8px !important;
    width: 40px;
    white-space: break-spaces;
    line-height: 1.2em !important;
  }
  #root
    > div
    > div
    > div:nth-child(2)
    > div
    > div.ant-row.ant-row-center.ant-row-middle.ant-layout-content
    > div
    > div:nth-child(2)
    table {
    font-size: 7px !important;
  }
  td span.ant-tag {
    padding: 2px !important;
    font-size: 5px !important;
    line-height: 1em !important;
  }
  /* CRM Overview End*/

  /* Leave Form Start */

  .form-all ul.form-section {
    padding: 0 !important;
  }
  .mobile-collapse-button {
    position: absolute;
    top: 10px;
    right: -50px;
    z-index: 1000;
    background-image: linear-gradient(280deg, #00aaf1, #03488a) !important;
    color: #fff;
    font-size: 14px;
    line-height: 1.5714285714285714;
    height: 32px;
    padding: 4px 15px;
    border-radius: 6px;
  }
  /* Leave Form End */
}
