.navigation {
  z-index: 1000;
  background: #fff;
  border-right: 1px solid #edf0f5;
}

.logo {
  height: 32px;
  margin: 16px 16px 30px 16px;
  padding-left: 6px;
}
