/* Signup Page CSS Start */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.loginpage .ant-col-lg-order-1 > main,
.loginpage .ant-col-lg-order-2 > main {
  padding-top: 0 !important;
}
.loginpage .ant-col-lg-order-1,
.loginpage .ant-col-lg-order-2 {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}
.loginpage .ant-col-lg-order-1 > main > div > img {
  margin-left: -50px !important;
  margin-bottom: 0 !important;
}
.loginpage .ant-col-lg-order-1 > main {
  max-width: 80% !important;
}

.loginpage .ant-col-lg-order-1 > main > div > h3 {
  font-size: 40px;
  line-height: 1.2;
}
.loginpage .ant-col-lg-order-1 > main > div > div:nth-child(6),
.loginpage .ant-col-lg-order-1 > main > div > div:nth-child(7) {
  max-width: 350px !important;
}
.loginpage .ant-col-lg-order-1 > main > div > div:nth-child(7) img:nth-child(3) {
  margin: -2px 15px !important;
  width: 50px !important;
}
.loginpage .ant-col-lg-order-1 > main > div > div:nth-child(7) img:nth-child(4) {
  margin: -4px 15px !important;
  width: 55px !important;
}
.loginpage .ant-col-lg-order-2 input {
  line-height: 1.3em !important;
}

.loginpage .list-checked ul li:before {
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-image: url(https://www.dymax.a2hosted.com/static/media/checklist.e76cb762.svg);
  content: '';
  margin-top: 0.125rem;
  margin-left: -20px !important;
}
.loginpage .list-checked ul {
  list-style: none;
}
.loginpage .list-checked strong {
  width: 420px !important;
  display: block;
}
.loginpage .list-checked ul {
  max-width: 550px;
}
.loginpage .list-checked ul li {
  width: 40% !important;
  display: inline-block;
}
.loginpage .ant-layout {
  background: #eaf6ff !important;
}
.loginpage .ant-col-lg-order-1 {
  background-image: url(https://dymaxtech.com/wp-content/uploads/2024/05/asl.png);
  background-position: center left;
  background-size: contain;
  background-repeat: no-repeat;
}
.loginpage .ant-col-lg-order-2 {
  background-image: url(https://dymaxtech.com/wp-content/uploads/2024/05/asr.png);
  background-position: center right;
  background-size: contain;
  background-repeat: no-repeat;
}
.loginpage .ant-col-lg-order-2 main {
  background-image: linear-gradient(#00aaf1, #03488a);
  padding: 40px !important;
  padding-bottom: 10px !important;
  border-radius: 5px !important;
}
.loginpage .ant-col-lg-order-2 main *,
.loginpage .ant-col-lg-order-2 main input::placeholder {
  color: #fff !important;
}
.loginpage .ant-col-lg-order-2 main .ant-input-affix-wrapper {
  background: #ffffff00 !important;
  border-color: #fff !important;
  border-radius: 50px !important;
}
.loginpage button[type='submit'] {
  background: #323232 !important;
  border-radius: 50px !important;
}
.loginpage .ant-col-lg-order-2 main .ant-divider {
  border-color: #fff !important;
}

/* Signup Page CSS End */

/* Dashboard CSS End */

.ant-tag {
  margin-right: 0 !important;
  justify-content: end !important;
}
.ant-page-header-heading-title {
  text-transform: capitalize;
}
.whiteBox.shadow .ant-table-cell {
  text-align: left !important;
}
.ant-tag,
.ant-statistic-content-value {
  text-transform: capitalize;
}
.proposal-section .search-input {
  height: 30px !important;
  position: relative !important;
  background-color: #fff !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 4px !important;
}
.proposal-container .whiteBox.shadow,
.all-proposals-container {
  padding: 50px 40px !important;
  margin: 100px auto !important;
  width: 100% !important;
  max-width: 1200px !important;
}
.proposal-image img {
  height: 150px !important;
}
.ant-card-body {
  padding: 15px !important;
}
.proposal-box .proposal-info a {
  background-color: #0367b7 !important;
  color: white !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  margin: 5px 10px !important;
  display: inline-block;
}
.proposal-title {
  top: 54% !important;
}
.category {
  padding-top: 7px !important;
}
.proposal-container .ant-upload .ant-btn {
  background-color: #d2cece !important;
  color: black !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  display: block;
  position: relative;
  height: 45px !important;
  margin: 20px 0 !important;
}

.proposal-container .ant-btn[title='Remove file'] {
  background-color: #ffffff !important;
  color: black !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  display: block;
  position: relative;
  height: 39px !important;
  margin: 15px 0 !important;
  background-image: url('https://cdn-icons-png.flaticon.com/512/11494/11494408.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.proposal-container .ant-btn.publish,
.proposal-container .ant-btn.preview {
  background-color: #0367b7 !important;
  color: white !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  display: inline-block;
  height: 45px !important;
  width: 25% !important;
  margin: auto !important;
  margin-bottom: 5px !important;
  margin-right: 5px !important;
  text-align: center !important;
}
.ant-upload-list-picture .ant-upload-list-item {
  margin-bottom: 20px !important;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 80px);
  min-height: 0 !important;
  height: auto !important;
}
.proposal-container .ant-form-item-label {
  min-width: 103px !important;
  text-align: left !important;
}
.whiteBox.shadow > .pad10 {
  justify-content: center !important;
  gap: 30px;
}
th {
  text-transform: capitalize;
}
.ant-select-selection-item {
  text-align: left !important;
}

.clcsv-button > button,
.csv-button {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  z-index: 9999 !important;
  width: 180px !important;
  height: 40px !important;
  margin: auto !important;
  background: #1b98f5 !important;
  color: white !important;
  border-radius: 0 0px 10px 10px !important;
  margin-top: -1px !important ;
}
.clcsv-button > button:hover,
.csv-button:hover {
  color: white !important;
  scale: 1.1;
}
aside.navigation .ant-layout-sider-children {
  position: fixed;
  display: block;
  width: auto;
}
aside.ant-layout-sider-collapsed.navigation .ant-layout-sider-children {
  width: 80px;
  max-width: 79px;
}
.ant-col-lg-18 .whiteBox.shadow,
.ant-col-lg-12 .whiteBox.shadow {
  height: 100%;
}
.ant-col-lg-12 tr th:first-child,
.ant-col-lg-12 tr td:first-child {
  padding-left: 20px !important;
}
.proposal-container .ant-card-body {
  text-align: center;
}
.proposal-container .ant-card-body * {
  text-align: left !important;
}
/*Side Bar changes after antd Update*/
.custom-sider .ant-layout-sider-children {
  background-color: #ffffff !important;
}
.ant-layout-sider-trigger svg {
  fill: #006aff !important;
}
:where(.css-42nv3w).ant-layout .ant-layout-sider {
  position: relative;
  min-width: 0;
  background: #ffffff;
  transition: all 0.1s, background 0s;
}
/* custom-antd.css */
:where(.css-42nv3w).ant-layout .ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #e6f4ff;
  cursor: pointer;
  transition: all 0.1s;
}

/* Dashboard CSS End */

:where(.css-dev-only-do-not-override-1b0bdye).ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  line-height: 30px;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  position: relative;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #1677ff;
  border: 1px solid transparent;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

aside.ant-layout-sider.ant-layout-sider-dark.navigation.custom-sider > div > ul {
  height: calc(100vh - 335px) !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding-bottom: 30px;
}

/* Media Query Start */
@media (max-width: 1450px) and (min-width: 800px) {
  .proposal-container .whiteBox.shadow,
  .all-proposals-container {
    width: 90% !important;
  }
  .proposal-title {
    width: 70% !important;
    left: 15% !important;
  }
  .ant-card-body {
    padding: 10px !important;
  }
  .proposal-title {
    font-size: 16px !important;
  }
}
@media (max-width: 1400px) and (min-width: 800px) {
  .proposal-box {
    flex-basis: calc(50% - 20px) !important;
  }
}
.hover-effect-button {
  background-color: #14a800;
  transform: scale(1);
  transition: background-color 0.3s, transform 0.3s;
}

.hover-effect-button:hover {
  background-color: #0f8900; /* Change the background color to a darker shade on hover */
  transform: scale(1.1); /* Enlarge the button on hover */
  /* You can add other hover effects as needed */
}

/* Media Query End */

.clock-image {
  animation: punchInClock 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  width: 99px;
  display: block;
  margin: 20px auto;
  opacity: 0;
}

@keyframes punchInClock {
  0% {
    transform: rotate(0deg) scale(0);
    opacity: 0;
  }
  10% {
    transform: rotate(-90deg) scale(0.5);
    opacity: 0.2;
  }
  30% {
    transform: rotate(10deg) scale(1.2);
    opacity: 0.8;
  }
  60% {
    transform: rotate(-5deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
}

.attendance-card {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #1e1e1e;
  color: #5a5a5a;
  font-weight: 400;
  border-radius: 22px;
}

.attendance-card:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  transition: box-shadow 0.3s, transform 0.3s;
}

.attendance-report-card {
  height: 120px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  color: #5a5a5a;
  font-weight: 400;
  border-radius: 22px;
}

.attendance-report-card:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  transition: box-shadow 0.3s, transform 0.3s;
}
.proposal-container .ant-divider-horizontal.ant-divider-with-text-left::before {
  display: none !important;
}
.proposal-container .ant-divider-inner-text {
  padding-left: 0 !important;
}
.main-prop-inner > img {
  width: 100px !important;
  height: 100px !important;
  padding: 12px;
  object-fit: contain;
  border-radius: 50% !important;
  background: #fff;
  margin-bottom: -50px !important;
  z-index: 999 !important;
  position: relative;
}

.main-prop-inner > div:nth-child(2) {
  background-image: linear-gradient(90deg, #03488a 0%, #00aaf1 100%) !important;
  min-height: 300px !important;
  max-width: 98% !important;
  padding: 50px !important;
  padding-top: 70px !important;
  margin: auto !important;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-prop-inner > div:nth-child(2) > h1 {
  margin: 0 !important;
  padding: 0 !important;
  max-width: 915px;
}
.main-prop-inner > div:nth-child(2) > a > img {
  width: 25px;
  margin-right: 5px;
  margin-bottom: 3px;
}
.main-prop-inner > div:nth-child(2) > a {
  height: auto !important;
  color: #000 !important;
  padding: 5px 20px !important;
  margin-top: 20px !important;
}
.main-prop-inner > div:nth-child(4) {
  background-image: linear-gradient(90deg, #03488a 0%, #00aaf1 100%) !important;
  min-height: 300px !important;
  max-width: 98% !important;
  padding: 50px !important;
  margin: auto !important;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px !important;
}
.main-prop-inner > div:nth-child(4) h2,
.main-prop-inner > div:nth-child(4) p {
  color: white !important;
}
.main-prop-inner > div:nth-child(4) > a > img {
  width: 25px;
  margin-right: 5px;
  margin-bottom: 3px;
}
.main-prop-inner > div:nth-child(4) > a {
  height: auto !important;
  color: #000 !important;
  padding: 5px 20px !important;
  display: block !important;
  width: 140px !important;
  position: relative !important;
  margin: 0 auto;
}
.main-prop-inner > div:nth-child(3) p,
.main-prop-inner > div:nth-child(3) li,
.main-prop-inner > div:nth-child(3) div {
  font-size: 18px !important;
}
.main-prop-inner > div:nth-child(3) h2 {
  font-size: 30px !important;
}
.main-prop-inner > div:nth-child(3) button.image-gallery-thumbnail {
  width: 33.3% !important;
}
.main-prop-inner > div:nth-child(3) button.image-gallery-thumbnail img {
  height: auto !important;
}
.main-prop-inner {
  background: url(https://dymaxtech.com/wp-content/uploads/2023/11/mul-l1.png),
    url(https://dymaxtech.com/wp-content/uploads/2023/11/mul-l2.png),
    url(https://dymaxtech.com/wp-content/uploads/2023/11/mul-l1.png),
    url(https://dymaxtech.com/wp-content/uploads/2023/11/mul-l2.png);
  background-repeat: no-repeat;
  background-position: 0 15%, 100% 45%, 0 90%, 100% 100%;
  padding-bottom: 20px;
}
.carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.carousel .item {
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  margin-right: 10px;
}
.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.image-grid img {
  width: 100%;
  height: auto;
  object-fit: cover;
  scroll-snap-align: start;
}

.shadow-box {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 20px;
  overflow: hidden;
}

img.upwork-logo-float:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease 0s;
}

img.upwork-logo-float {
  transition: transform 0.5s ease 0s;
}

.ant-tabs.ant-tabs-left {
  padding: 50px 0 !important;
}
.ant-tabs.ant-tabs-left .ant-tabs-tab-active,
.ant-tabs-tab.ant-tabs-tab:hover {
  background: #1b98f5 !important;
  color: #fff !important;
  border-radius: 5px !important;
}
.ant-tabs.ant-tabs-left .ant-tabs-nav-wrap {
  padding-right: 20px !important;
}
.ant-tabs.ant-tabs-left .ant-tabs-ink-bar {
  display: none !important;
}
.ant-tabs.ant-tabs-left .ant-tabs-tab.ant-tabs-tab {
  background: #e7e7e7;
  border-radius: 5px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000e2 !important;
}
.ant-tabs.ant-tabs-left .ant-tabs-tabpane h2 {
  font-size: 21px !important;
  margin-bottom: 1em;
}

.ant-tabs.ant-tabs-left .ant-tabs-tabpane * {
  font-size: 18px !important;
  text-align: justify;
}

.sticky-tabs {
  position: sticky;
  top: 0;
  transition: top 0.3s ease-in-out;
}
.ant-carousel .slick-dots li button {
  width: 30px;
  height: 30px;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: #1890ff;
}
.ant-carousel .slick-slide {
  height: 600px;
  overflow-y: auto;
}
.carousel-item {
  height: 300px;
  overflow: auto;
  text-align: left;
  text-justify: distribute;
}
.carousel-container {
  height: 100vh;
  overflow: auto;
}

.ant-carousel-vertical .slick-dots-left {
  right: auto;
  left: -25px !important;
}

.drag-col {
  border: 2px dashed #1b97f580;
  min-height: 1900px;
}

.drag-col > div > div {
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1);
  /* transition: 0.3s; */
  padding: 20px;
  overflow: hidden;
  margin-bottom: 20px !important;
}

.greeting-card .ant-card-body {
  flex-direction: column;
  color: white;
  padding: 20px;
  border-radius: 8px;
  width: auto;
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;
  position: relative;
}

.greeting-card h1 {
  color: white;
  font-size: 2.5em;
  font-weight: bold;
  text-align: center;
  margin-top: 35px;
  text-transform: capitalize !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}
.greeting-card h2 {
  color: white;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.ant-card.ant-card-bordered.greeting-card {
  margin-top: 50px !important;
}
.greeting-card h1 {
  margin-bottom: 0 !important;
}
.greeting-card h2 {
  margin-bottom: 20px !important;
}
.att-logo {
  padding: 20px;
  width: 100px;
  height: auto !important;
  margin-left: -5px !important;
  margin-top: -5px;
}

.logo-attendance {
  width: 100px !important;
  height: 100px !important;
  padding: 5px;
  margin-top: 100px;
  object-fit: fill;
  border-radius: 50% !important;
  background: #fff !important;
  z-index: 999 !important;
  position: absolute;
  top: -58%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.attendance-card-left {
  text-align: center !important;
}
.attendance-card-right > div {
  height: 100% !important;
}
.attendance-card-right > div > div {
  height: 100% !important;
  align-items: center !important;
}
.attendance-card-right .ant-divider {
  height: 50% !important;
}
.attend-card {
  min-height: 0 !important;
}
.attend-card h3 {
  margin-bottom: 0 !important;
  line-height: 40px !important;
}
.attend-card h3 .ant-tag {
  float: right !important;
  font-size: 18px !important;
  padding: 10px !important;
}
.ant-row.attendance-card-row {
  margin-left: -5px !important;
  margin-right: -5px !important;
}
.attendance-overview-row {
  margin: 0 !important;
}
.ant-avatar {
  background-color: #1677ff !important;
}
.reminder-date {
  background-color: #1677ff7b; /* Light blue background */
  border-radius: 30%; /* Rounded corners */
}
.leaves-from {
  background: url(https://dymaxtech.com/wp-content/uploads/2023/11/mul-l1.png),
    url(https://dymaxtech.com/wp-content/uploads/2023/11/mul-l2.png),
    url(https://dymaxtech.com/wp-content/uploads/2023/11/mul-l1.png),
    url(https://dymaxtech.com/wp-content/uploads/2023/11/mul-l2.png);
  background-repeat: no-repeat;
  background-position: 0 15%, 500% 45%, 0 90%, 100% 100%;
  padding-bottom: 20px;
}

.running-task {
  color: #2c3e50; /* Dark green text */
  background-color: #c8e6c9; /* Light green background */
  padding: 5px;
  border-radius: 5px;
  animation: blink 1s infinite alternate;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* styles.css */
.custom-sider {
  position: relative;
}

.custom-sider .ant-btn.collapse-button {
  position: absolute;
  top: 20px;
  right: -50px;
  z-index: 1000;
  background-image: linear-gradient(280deg, #00aaf1, #03488a) !important;
  color: #ffffff;
}

.ant-layout-sider-children > div > img[alt='User Avatar'] {
  border: 5px solid #00000010;
  object-fit: contain;
}
.ant-layout-sider-children > div > img[alt='User Avatar']:hover {
  border-color: #0196dc !important;
}
.ant-layout-sider-children > div > p:nth-child(2) {
  color: #424448 !important;
  text-transform: capitalize !important;
  font-family: 'Poppins' !important;
  margin-top: 10px !important;
  font-size: 18px;
  font-weight: 500 !important;
  margin-bottom: 5px;
}
.ant-layout-sider-children > div > p:nth-child(3) {
  color: #424448 !important;
  text-transform: capitalize !important;
  font-family: 'Poppins' !important;
  margin-top: 0px !important;
  font-size: 13px;
  font-weight: 300 !important;
}
span.ant-input-wrapper.ant-input-group {
  width: 80%;
  margin: auto;
  background: #efefef !important;
  border-radius: 50px !important;
}
span.ant-input-wrapper.ant-input-group input {
  background: transparent !important;
  box-shadow: none !important;
  border: 0 !important;
  height: 42px !important;
}
span.ant-input-wrapper.ant-input-group .ant-input-search-button {
  background: #424448 !important;
  border-radius: 50% !important;
  width: 30px !important;
  height: 30px !important;
  text-align: center !important;
  margin-right: 8px !important;
  padding: 0;
}
span.ant-input-wrapper.ant-input-group .ant-input-search-button:hover {
  background: #0196dc !important;
}
.ant-layout-sider-children .ant-divider {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
}
span.sidebar-heading {
  color: #424448 !important;
  text-transform: capitalize !important;
  font-family: 'Poppins' !important;
  margin-top: 0px !important;
  font-size: 20px;
  font-weight: 600 !important;
}
.ant-input-search-with-button {
  margin-bottom: 10px !important;
}
.ant-menu-item-disabled {
  margin-top: 20px !important;
}
li.ant-menu-item:hover {
  background-image: linear-gradient(280deg, #00aaf1, #03488a);
  border-radius: 0 50px 50px 0 !important;
  color: #fff !important;
}
li.ant-menu-item * {
  transition: none !important;
}
.attendance-card-row button.ant-btn:not(.ant-btn-icon-only) {
  margin: 10px 0 !important;
}
.ant-layout-has-sider > div {
  background-image: url(https://dymaxtech.com/wp-content/uploads/2024/06/erp-back.png) !important;
  background-size: contain !important;
  background-position: top right !important;
  background-color: #f0f8ff !important;
  background-repeat: no-repeat !important;
}
.ant-row.attendance-card-row:nth-child(2) > div:nth-child(1) {
  max-width: 100% !important;
  flex: 0 0 100% !important;
}

#root
  > div
  > div
  > div:nth-child(2)
  > div
  > div.ant-row.ant-row-center.ant-row-middle.ant-layout-content.css-42nv3w
  > div
  > div.ant-row.ant-row-center.ant-row-top.css-42nv3w
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div:nth-child(1)
  > div
  > div
  > input {
  width: 80%;
}
#root
  > div
  > div
  > div:nth-child(2)
  > div
  > div.ant-row.ant-row-center.ant-row-middle.ant-layout-content.css-42nv3w
  > div
  > div.ant-row.ant-row-center.ant-row-top.css-42nv3w
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div:nth-child(1)
  > div
  > div
  > button {
  width: 18%;
  margin-left: 2% !important;
}
.ant-list.ant-list-split {
  min-height: 200px;
  max-height: 200px;
  overflow-y: auto;
}
.ant-list.ant-list-split::-webkit-scrollbar,
.p-notes .ant-card-body > div:nth-child(1)::-webkit-scrollbar {
  width: 5px;
}
.ant-list.ant-list-split::-webkit-scrollbar-track,
.p-notes .ant-card-body > div:nth-child(1)::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.ant-list.ant-list-split::-webkit-scrollbar-thumb,
.p-notes .ant-card-body > div:nth-child(1)::-webkit-scrollbar-thumb {
  background: #0196dc;
}

.ant-list.ant-list-split::-webkit-scrollbar-thumb:hover,
.p-notes .ant-card-body > div:nth-child(1)::-webkit-scrollbar-thumb:hover {
  background: #0196dc;
}
.p-notes .ant-card-body > div:nth-child(1) {
  min-height: 200px;
  max-height: 200px;
  overflow-y: auto;
}
#root
  > div
  > div
  > div:nth-child(2)
  > div
  > div.ant-row.ant-row-center.ant-row-middle.ant-layout-content.css-42nv3w
  > div
  > div.ant-row.ant-row-center.ant-row-top.css-42nv3w
  > div:nth-child(2)
  > div:nth-child(2) {
  max-width: 100% !important;
  flex: 0 0 100% !important;
  padding: 0 15px !important;
}
.attendance-overview-row > .ant-col-12:nth-child(1) > div {
  padding: 20px 30px 0px 30px !important;
  background: #fff !important;
  border-radius: 5px !important;
  box-shadow: 0 0 20px 3px rgba(150, 190, 238, 0.15) !important;
  border: 1px solid #f0f0f0;
  transition: all 0.3s ease-in-out;
}
.attendance-overview-row > .ant-col-12:nth-child(1) > div:hover {
  box-shadow: 0 0 30px 8px rgba(150, 190, 238, 0.25) !important;
}
.greeting-card .ant-card-body {
  background-image: linear-gradient(280deg, #00aaf1, #03488a) !important;
}
.headerIcon {
  background: #fff !important;
  padding: 0 0 15px 0 !important;
}
.attend-card > div > div {
  width: 50% !important;
  display: inline-block;
  vertical-align: middle;
}
.attend-card > div > div:nth-child(1) {
  text-align: left !important;
  padding-left: 10px !important;
}
.attend-card > div > div:nth-child(1) p {
  margin-bottom: 0 !important;
}
.attend-card > div > div:nth-child(2) {
  text-align: right !important;
  padding-right: 10px !important;
}
.attend-card > div > div:nth-child(1) p:nth-child(1) {
  font-size: 16px;
  color: #424448 !important;
  font-weight: 500 !important;
  font-family: 'Poppins' !important;
}
.attend-card > div > div:nth-child(1) p:nth-child(2) {
  font-size: 14px;
  color: #424448 !important;
  font-weight: 300 !important;
  font-family: 'Poppins' !important;
}
