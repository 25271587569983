@import 'antd/dist/reset.css';
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v26/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNic3c3ig.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v26/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNiSnc3ig.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v26/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshRTM.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v26/XRXI3I6Li01BKofiOc5wtlZ2di8HDGUmRTM.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v26/XRXI3I6Li01BKofiOc5wtlZ2di8HDFwmRTM.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v26/XRXI3I6Li01BKofiOc5wtlZ2di8HDDsmRTM.ttf) format('truetype');
}
.ant-layout {
  background: #f9fafc !important;
}
.site-layout .site-layout-background {
  background: #fff;
}
[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}
.ant-layout-sider-trigger {
  background: #fff;
  border-top: 1px solid #edf0f5;
  border-right: 1px solid #edf0f5;
  color: #4f5d75;
}
.ant-layout-sider-zero-width-trigger {
  top: 5px;
  right: 10px;
  color: #001529;
  background: none;
  font-size: 20px;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none !important;
}
.ant-dropdown-menu {
  border-radius: 6px;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.login-form button[type='submit'] {
  margin-bottom: 20px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
.ant-table-thead > tr > th {
  font-weight: 700;
}
.ant-typography strong {
  font-weight: 700;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
* {
  font-family: 'Nunito', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
}
.centerAbsolute {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 32px;
  height: 38px;
  margin-left: -16px;
  margin-top: -19px;
}
.whiteBox {
  background: #fff;
  border-radius: 6px;
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.shadow {
  box-shadow: 0px 0px 20px 3px rgba(150, 190, 238, 0.15);
}
.shadow:hover {
  box-shadow: 0px 0px 30px 8px rgba(150, 190, 238, 0.25);
}
.line {
  border-top: 1px solid #edf0f5;
  width: 100%;
}
.left {
  float: left;
}
.right {
  float: right;
}
.pad5 {
  padding: 5px;
}
.pad10 {
  padding: 10px;
}
.pad15 {
  padding: 15px;
}
.pad20 {
  padding: 20px;
}
.pad25 {
  padding: 20px;
}
.pad30 {
  padding: 20px;
}
.mrg5 {
  margin: 5px;
}
.mrg10 {
  margin: 10px;
}
.mrg15 {
  margin: 15px;
}
.mrg20 {
  margin: 20px;
}
.mrg25 {
  margin: 20px;
}
.mrg30 {
  margin: 20px;
}
.alignRight {
  text-align: right;
}
.alignLeft {
  text-align: left;
}
.center {
  justify-content: center;
}
.strong {
  font-weight: 700;
}
.space10 {
  height: 20px;
  width: 100%;
  display: block;
}
.space20 {
  height: 20px;
  width: 100%;
  display: block;
}
.space30 {
  height: 30px;
  width: 100%;
  display: block;
}
.space40 {
  height: 30px;
  width: 100%;
  display: block;
}
.space50 {
  height: 50px;
  width: 100%;
  display: block;
}
.space60 {
  height: 60px;
  width: 100%;
  display: block;
}
.space70 {
  height: 70px;
  width: 100%;
  display: block;
}
.list-checked {
  padding-left: 0px;
  list-style: none;
}
.list-checked-item {
  position: relative;
  display: block;
  color: #677788;
  padding-left: 1.75rem;
  margin-bottom: 30px;
  list-style: none;
}
.list-checked-item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background-image: url(../images/checklist.svg);
  content: '';
  margin-top: 0.125rem;
}
.sideContent * {
  color: #4f5d75 !important;
}
.navigation {
  z-index: 1000;
  background: #fff;
  border-right: 1px solid #edf0f5;
}
.logo {
  height: 32px;
  margin: 16px 16px 30px 16px;
  padding-left: 6px;
}
.headerIcon {
  position: relative;
}
.headerIcon .ant-avatar {
  float: right;
  margin-left: 10px;
  margin-top: 15px;
  color: #4f5d75;
  background: transparent;
}
.headerIcon .ant-avatar:hover {
  background: #fff;
  box-shadow: 0px 0px 10px 4px rgba(150, 190, 238, 0.3);
}
.headerIcon .last {
  margin-right: 30px;
}
.profileDropdown .pad15 {
  display: flex;
}
.profileDropdown .info {
  float: left;
  display: inline;
  padding-left: 15px;
}
.profileDropdown .info p {
  margin-bottom: 0;
}
.sidePanel {
  border-right: 1px solid #edf0f5;
  background: #fff;
}
.sidePanelContent {
  transition: all 0.3s ease-in-out;
  margin-top: 0;
}
.panelBox,
.panelBox * {
  transition: all 0.3s ease;
}
.collapseBoxHeader {
  width: 100%;
  padding: 17px 30px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #f9fafc;
  border-top: 1px solid #edf0f5;
  border-bottom: 1px solid #edf0f5;
}
.box {
  width: 100%;
  padding: 20px;
}
.BottomCollapseBox {
  padding: 20px;
  opacity: 1;
}
.TopCollapseBox {
  min-height: 450px;
  padding: 0px 20px 20px;
  opacity: 1;
}
.collapseBox {
  margin-top: -400px;
  position: relative;
  background: #fff;
  z-index: 1;
}
.collapsed {
  overflow: hidden;
  height: 250px;
  margin-top: 0 !important;
}
.collapseBox .whiteBg {
  display: none;
  background-color: hsla(0, 0%, 100%, 0);
}
.collapsed .whiteBg {
  position: absolute;
  top: 58px;
  width: 100%;
  height: 100%;
  display: inline-block;
  background-color: hsla(0, 0%, 100%, 0.8);
  z-index: 9;
}
.moneyInput {
  width: 100%;
}
.moneyInput input {
  text-align: right;
}
